<template>
  <div class="meta-list-input"
       :class="{
         'meta-list-input--editing':mode,
       }"
       :style="{'--text-align':align}">
    <el-input
              class="editInput"
              ref="input"
              v-model="content"
              :style="{width:inputWidth}"
              @keydown="handleKeydown"
              @click="handleInputClicked"
              @blur="handleInputBlur"
              @compositionstart="handleCompositionstart"
              @compositionend="handleCompositionend"
    ></el-input>
    <div @click="handleBoardClicked"
         v-if="!mode"
         class="meta-list-input-board"
         :class="{
            'meta-list-input-board--empty': !content,
          }"
         >
      <span class="truncate" ref="board">{{content ? content : boardPlaceHolder}}</span>
    </div>
  </div>
<!--  <span class="meta-list-scaler" ref="scaler">-->
<!--      {{-->
<!--      content ? content : boardPlaceHolder-->
<!--    }}-->
<!--  </span>-->

</template>

<script>
import {colorLog} from "../../util";

export default {
  name: "MetaListInput",
  emits:["onInputBlur","onContentChanged","update:editMode","update:value"],
  props:{
    value:{
      require:true
    },
    editMode:{
      require:false,
      default:false,
    },
    boardPlaceHolder:{
      require:false,
      default:''
    },
    align:{
      require:false,
    },
    disable:{
      require:false,
    }
  },
  data(){
    return {
      mode:this.editMode,
      content:this.value,
      contentCopy:'',
      inputWidth:'',
      disableEdit:false,
      compositionStart:false,
      useScaler:false
    }
  },
  watch:{
    mode(val){
      this.$emit("update:editMode",val);
    },
    content:{
      handler(val){
        this.$emit("update:value",val);
      },
    }
  },
  mounted() {
    // document.querySelector('.editInput').oninput = this.handleInput;
  },
  methods:{
    handleInputClicked(e){

    },
    handleBoardClicked(e){
      if(this.disable) return;
      this.mode = true;
      this.contentCopy = this.content;
      this.focusInput();
    },
    handleInputBlur(e){
      this.$emit('onInputBlur',e,this);

      //新旧数据不能一样,新数据不能为空
      if(this.contentCopy !== this.content && !this.disableEdit && /\S/.test(this.content)){
        this.$emit('onContentChanged',this.content);
      }else{
        this.content = this.contentCopy;
      }

      this.mode = false;

    },
    updateInputWidth(){

    },
    handleKeydown(e){
      if(e.keyCode === 13){
        // 按下回车
        this.$refs.input.blur();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if(e.keyCode == 27){
        //按下esc 此时防止blur方法里面的更新,去设置一个disableEdit做判断
        this.disableEdit = true;
        this.$refs.input.blur();
        this.disableEdit = false;

        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if(e.code == 'Backspace'){
        //按下backspace
        this.useScaler = true;
        return;
      }

    },
    focusInput(){
      this.$nextTick(()=>{
        this.$refs.input.focus();
      })
    },
    handleInput(e){

      if(this.useScaler){
        this.upgradeWidthWithScaler();
        this.useScaler = false;
      }else{
        this.inputWidth = this.$refs.input.input.scrollWidth+ 'px';
      }
    },
    upgradeWidthWithScaler(){
      let scaler = this.$refs.scaler;
      let value = this.$refs.input.input.value;
      scaler.innerText = value;
      this.inputWidth = scaler.getBoundingClientRect().width +'px';
    },
    handleCompositionstart(e){
      this.compositionStart = true;
    },
    handleCompositionend(e){
      // this.upgradeWidthWithScaler();
      this.compositionStart = false;
    },
  }
}
</script>

<style scoped>
.meta-list-input{
  --text-align:left;
  position: relative;
}
.meta-list-input--editing::before{
  content:'\3000';
  width:0;
  display: inline-block;
}
.meta-list-input >>> .el-input__inner{
  text-align: var(--text-align);
  border-color: transparent !important;
}
.meta-list-input-board{
  display: inline-block;
  line-height: 38px;
  width:100%;
  padding: 0 15px;
  border:1px solid transparent;
  box-sizing:border-box;
}
.meta-list-input-board:hover{
  border-radius: 4px;
  box-shadow: 0 0 3px var(--gray-2);
}
.meta-list-input-board::before{
  content:'\3000';
  width:0;
  display: inline-block;
}
.meta-list-input-board>span{
  width:100%;
  vertical-align: -0.2em;
  display: inline-block;
  line-height: 1;
}
.meta-list-input-board--empty{
  color:var(--gray-2);
}
.meta-list-input-board{
  cursor:pointer;
}
.editInput{
  visibility:hidden;
  position:absolute;
  user-select: none;
  background: white;
  z-index: 2;
  box-shadow: 0 0 3px var(--gray-3);
  border-radius: 4px;
}
.meta-list-input--editing .meta-list-input-board{

}
.meta-list-input--editing .editInput{
  position:static;
  visibility:visible;
  user-select: initial;
  width: fit-content;
  height: fit-content;
}
.meta-list-scaler{
  visibility:hidden;
  position:absolute;
  user-select: none;
  padding:0 15px;
  border: 1px solid;
  white-space: nowrap;
  min-width: calc(100% - 32px);
}
</style>
