<template>
    <div class="tl">
        <div class="t1">
            <span class="contract-title" @click="getToContractViewPage">{{contract.title}}</span>
            <span v-if="contract.otherParties && contract.otherParties.length > 0">（{{JSON.parse(contract.otherParties).join("、")}}）</span>
            已被设为已签署状态
        </div>
        <div class="t1">您可以添加下列事件到时间提醒：</div>
        <el-table
                v-loading="loading"
                element-loading-text="正在加载数据，请稍后..."
                row-class-name="contract-calendar-table-row"
                ref="calendarMultipleTable"
                :data="calendar"
                class="set-calendar-table"
                row-key="id"
                border
                max-height="350"
                @selection-change="calendarTableSelectionChanged"
        >
            <!--            -->
            <el-table-column type="selection" width="55" :selectable="calendarSelectable"/>
            <MetaListColumn label="时间" :min-width="150">
                <template #default="scope">
                    <DatetimePicker
                            :recoverable="false"
                            :class="{'c-red': !timeTextIsEffective(scope.row)}"
                            :placeholder="scope.row.timeText"
                            :contract-date-info="scope.row"
                            @onConfirm="tableTimestampChange(scope.row)"
                    />
                </template>
            </MetaListColumn>
            <MetaListColumn label="事件" :min-width="200">
                <template #default="scope">
                    <MetaListInput
                            :boardPlaceHolder="''"
                            :align="scope.align"
                            v-model:value="scope.row.event"
                            @onContentChanged="tableEventChange(scope.row)"
                    ></MetaListInput>
                </template>
            </MetaListColumn>
            <MetaListColumn label="描述" :min-width="200" :show-overflow-tooltip="true">
                <template #default="scope">
                    <MetaListInput
                            :boardPlaceHolder="''"
                            :align="scope.align"
                            v-model:value="scope.row.relateMoney"
                            @onContentChanged="tableRelateMoneyChange(scope.row)"
                    ></MetaListInput>
                </template>
            </MetaListColumn>
            <el-table-column label="关联待办" :show-overflow-tooltip="true" :width="tableShowTag ? 350 : 150">
                <template #header>
                    <div style="display: flex;">
                        <div v-if="!tableShowTag" style="cursor: pointer" @click="tableShowTag = true">
                            <svg-icon name="arrow-right" width="16px" height="16px"></svg-icon>
                        </div>
                        <div v-if="tableShowTag">关联待办</div>
                        <div v-if="tableShowTag" style="position: absolute; right: 20px; cursor: pointer" @click="tableShowTag = false; editRow = null">
                            <svg-icon name="arrow-left" width="16px" height="16px"></svg-icon>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <div v-if="tableShowTag" style="width: 100%;">
                        <div v-if="!editRow || editRow.rowId !== scope.row.rowId" @click="tagInputClicked(scope)">
                            <div class="tags-container" ref="tagContainer">
                                <div class="ctc-tag-wrapper"
                                     :key="tagData.id"
                                     v-for="tagData in scope.row.tagDTOS">
                                    <CertificateTag
                                            :tag-data="tagData"
                                            :voucherRequired="tagData.voucherRequired"
                                            :show-close-btn="false"
                                            :preventModifyResolution="true"
                                            @closeBtnClicked="() => {}"
                                            @preventChangeMode="preventChangeMode = $event"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="tag-composition" :rowid="scope.row.rowId">
                        </div>
                        <div v-if="scope.row.tagDTOS.length === 0 && (!editRow || editRow.rowId !== scope.row.rowId)">
                            <MetaListInput
                                    :boardPlaceHolder="'————'"
                                    :align="scope.align"
                                    @click="tagInputClicked(scope)"
                            ></MetaListInput>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="table-bottom-btns">
            <div class="table-row-add-btn" @click="tableRowAddClicked"> + 添加</div>
            <div class="set-calendar-desc">已选{{selectedRowNumbers}}项，系统将会在事件前<span style="text-decoration:underline;">30天</span>邮件提醒您</div>
        </div>
        <div class="calendar-btns">
            <el-button class="generate-calendar-btn" type="primary" @click="generateContractCalendar">添加提醒</el-button>
        </div>

        <div v-if="editRow" style="display:none;">
            <teleport :to="tagCompositionTeleportTo || '#app'">
                <TagComposition
                        ref="table-edit-tagComposition"
                        class="contract-deadline-tc"
                        style="flex:1;"
                        :selectable-tags-data="tags"
                        :selected-tags-data="editRow.tagDTOS"
                        :create-new-tag-data-default-type="TagType.NEED_RESOLVE"
                        :list-placeholder="'请选择或创建一个待办标签'"
                        :placeholder="'请关联待办'"
                        :list-z-index="2180"
                        :list-width="'auto'"
                        :list-max-height="200"
                        :disable-auto-change-mode="true"
                        :prevent-wrapper-modify-resolution="true"
                        :singleton-mode="true"
                        :external-requests="{
                          addTag:(name, type) => contractDateAddTag(name, type),
                          removeTagRelation:(tagId) => contractDateDeleteTag(tagId),
                        }"
                        @modeChanged="handleTagCompositionModeChanged"
                        @tagResolved="handleTagCompositionTagResolved"
                        @containerTagDataAppended="()=>{}" />
            </teleport>
        </div>


    </div>

</template>

<script>
    import {
        setContractCalendar,
        getContractCalendarCandidates,
        getTags,
        addTag
    } from "../api/api";
    import {DomEventListenerManager, MetaMessage} from "../util";
    import {globalTagClick} from "../assets/js/tag/tag";
    import MetaListColumn from "./list/MetaListColumn";
    import MetaListInput from "./list/MetaListInput";
    import DatetimePicker from '../components/date-picker/datetime-picker'
    import {TagCompositionMode} from "./tag/configure";
    import TagComposition from "../components/tag/TagComposition";
    import CertificateTag from "../components/tag/CertificateTag";
    import SvgIcon from "../components/SvgIcon/svgIcon";
    import {TagType} from "./tag/configure";

    export default {
        name: "ContractCalendar",
        components: {
            MetaListInput,
            MetaListColumn,
            DatetimePicker,
            TagComposition,
            CertificateTag,
            SvgIcon
        },
        props: ['contract', 'callback'],
        mounted() {
            console.log('ContractCalendar  mounted  contract === ', this.contract);
            this.initContractTags();
            this._getContractCalendarCandidates();
        },
        beforeUnmount() {
            this.domEventListenerManager.removeListener();
        },
        data() {
            return {
                metaMessage: new MetaMessage(),
                calendarTableSelection: [],
                currentCalendar: null,
                currentRowData: null,
                calendar: [],
                contractDeadline: 1957664972000,
                periodTimeDisabled: false,
                editTimeTextBackup: '', // 备份修改的文本
                effectiveDateStart: null, // 有效期开始时间
                effectiveDateEnd: null, // 有效期结束时间
                effectiveDateLength: null, // 有效期长度
                effectiveDateLegal: false,
                yearDayQuantity: 365,
                monthDayQuantity: 30,
                dayTimestamp: 24 * 60 * 60 * 1000,
                selectedRowNumbers: 0,
                tagCompositionTeleportTo: '',
                tags: [],
                rowId: -1,
                editRow: null,
                editTag: false,
                tableShowTag: false,
                TagType,
                addBtnId: 99999,
                domEventListenerManager: new DomEventListenerManager(),
                loading: false
            }
        },
        watch: {
        },
        methods: {
            bindClickEvent: function () {
                const dialog = document.querySelector('.contract-calendar-dialog');
                this.domEventListenerManager.registerListener(dialog,'click',(e) => {
                    console.log('document click --- ');
                    this.hideTagComposition(e.target);
                },{
                    capture: true
                })
            },
            hideTagComposition: function (clickTarget) {
                if (this.editRow) {
                    const tagComposition = this.$refs['table-edit-tagComposition'];
                    if (tagComposition) {
                        const tagCompositionEle = tagComposition.selfRef;
                        const tagListPopper = document.querySelector('.composition-tag-list-popper-wrapper');
                        const tagSettingPopper = document.querySelector('.mtl-setting-popper-wrapper');
                        if (!tagCompositionEle.contains(clickTarget) && !tagListPopper.contains(clickTarget) && !tagSettingPopper.contains(clickTarget)) {
                            this.editRow = null;
                        }
                    }
                }
            },
            initContractTags: function () {
                getTags().then(res => {
                    console.log('getTags  res:', res);
                    for (const tag of res.data.data) {
                        if (tag.type === TagType.NEED_RESOLVE) {
                            this.tags.push(tag);
                        }
                    }
                }).catch(err => {
                    console.error("initContractTags   err:", err);
                })
            },
            tableTimestampChange(row) {
                this.onTimestampOrEventChanged(row);
            },
            tableEventChange(row) {
                this.onTimestampOrEventChanged(row);
            },
            tableRelateMoneyChange(row) {
            },
            onTimestampOrEventChanged: function (row) {
                console.log('onTimestampOrEventChanged  row:', row);
                let selected = this.rowIsEffective(row);
                this.$refs.calendarMultipleTable.toggleRowSelection(row, selected);
                // this.selectedRowNumbers += selected ? 1 : -1;
            },
            _getContractCalendarCandidates: function () {
                this.loading = true;
                getContractCalendarCandidates(this.contract.id).then(res => {
                    this.loading = false;
                    console.log('getContractCalendarCandidates  res === ', res)
                    const contractDates = [];
                    for (const contractDate of res.data.data) {
                        contractDate['rowId'] = --this.rowId;
                        contractDate['tagDTOS'] = [];
                        contractDates.push(contractDate);
                    }
                    // const newRow = {
                    //     timestamp: null,
                    //     contractId: this.contract.id,
                    //     contractTitle: null,
                    //     parties: '[]',
                    //     event: '',
                    //     relateMoney: '',
                    //     isPeriodTime: false,
                    //     periodTimeNumber: 1,
                    //     periodTimeUnit: 2,
                    //     rowId: this.addBtnId
                    // };
                    // contractDates.push(newRow);
                    this.calendar = contractDates;
                    this.$nextTick(() => {
                        this.$refs.calendarMultipleTable.$el.querySelector('.el-table__body-wrapper').classList.add('custom-scroller');
                        this.setRowSelection();
                    })
                }).catch(err => {
                    this.loading = false;
                    console.error("getContractCalendarCandidates  err:", err);
                })
            },
            setRowSelection: function () {
                // this.selectedRowNumbers = 0;
                for (const row of this.calendar) {
                    if (row.timestamp != null && row.event != null && row.event.length > 0) {
                        // this.selectedRowNumbers += 1;
                        this.$refs.calendarMultipleTable.toggleRowSelection(row, true);
                    }
                }
            },
            calendarSelectable: function (row, index) {
                return this.rowIsEffective(row);
            },
            timeTextIsEffective: function (row) {
                return row.timestamp != null;
            },
            rowIsEffective: function (row) {
                return row.timestamp != null
                    && row.event != null
                    && row.event.length > 0
                    && (!row.isPeriodTime || (row.periodTimeNumber != null && row.periodTimeUnit != null));
            },
            generateContractCalendar: function () {
                console.log('this.calendarTableSelection  === ', this.calendarTableSelection);
                if (this.calendarTableSelection.length === 0) {
                    this.metaMessage['info']('请选择需要添加的日期');
                    return;
                }

                let selectionDataNotPass = this.calendarTableSelection.some((item) => {
                    return !item.timestamp;
                });
                if (selectionDataNotPass) {
                    this.metaMessage['info'](`未能识别条件：${selectionDataNotPass.timeText}，请重新设置日期`);
                    return;
                }
                const contractDates = this.calendarTableSelection.map(item => {
                    if (!item.isPeriodTime) item.isPeriodTime = false;
                    return item
                });

                for (const contractDate of contractDates) {
                    if (contractDate.tagDTOS) {
                        contractDate['tagIds'] = contractDate.tagDTOS.map(tag => tag.id);
                    } else {
                        contractDate['tagIds'] = [];
                    }
                }
                setContractCalendar(this.contract.id, contractDates).then(res => {
                    console.log('setContractCalendar   res ==== ', res);
                    if (res.data.code === 0) {
                        globalTagClick('generate-calendar');
                        this.$emit('callback');
                    } else {
                        this.metaMessage['error']('设置日历失败:' + res.data.msg);
                    }
                }).catch(err => {
                    console.error('setContractCalendar err === ', err);
                    this.metaMessage['error']('设置日历失败');
                });
            },
            calendarTableSelectionChanged: function (selection) {
                this.calendarTableSelection = selection;
                this.selectedRowNumbers = this.calendarTableSelection.length;
            },
            getToContractViewPage: function () {
                let newPage = this.$router.resolve({
                    name: 'contractView',
                    query: {
                        'id': this.contract.id,
                    }
                })
                window.open(newPage.href);
            },
            tableRowAddClicked: function () {
                const newRow = {
                    timestamp: null,
                    contractId: this.contract.id,
                    contractTitle: null,
                    parties: '[]',
                    event: '',
                    relateMoney: '',
                    isPeriodTime: false,
                    periodTimeNumber: 1,
                    periodTimeUnit: 2,
                    tagDTOS: [],
                    rowId: --this.rowId
                };
                this.calendar.push(newRow);
            },
            tagInputClicked: function (scope) {
                this.editRow = scope.row;
                this.editTag = true;
                this.$nextTick(() => {
                    this.tagCompositionTeleportTo = `.tag-composition[rowid="${scope.row.rowId}"]`
                    const tagComposition = this.$refs['table-edit-tagComposition'];
                    tagComposition.api.resetTagsPosition();
                    tagComposition.api.changeMode(TagCompositionMode.INTACT_EDIT);
                    this.bindClickEvent();
                })
            },
            contractDateAddTag: function (name, type) {
                return addTag({name: name, type: type});
            },
            contractDateDeleteTag: function (tagId) {
                // 不真实发请求，点击“保存”才和实体一起更新
                return Promise.resolve({
                    data:{code:0}
                });
            },
            handleTagCompositionModeChanged(mode, popperVisible){
            },
            handleTagCompositionTagResolved: function (tagData) {
            },
        }

    }
</script>

<style scoped>

    .contract-title {
        color: var(--lightBlue);
        text-decoration:underline;
        cursor: pointer;
    }

    .tl {
        text-align: left;
        padding: 0 60px;
    }

    .t1 {
        color: #7f7f7f;
        font-size: 16px;
    }

    .calendar-btns {
        margin-top: 20px;
        height: 50px;
        text-align: right;
    }

    .c-red {
        color: red;
    }

    .generate-calendar-btn {
        margin-right: 30px;
    }

    .is-selected {
        color: #1989fa;
    }

    .calendar-edit-img-container {
        display: inline-block;
    }

    .calendar-edit-img {
        display: inline-block;
        width: 20px;
        cursor: pointer;
        margin: 0 10px;
        vertical-align: top;
    }

    .time-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .time-text {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        line-height: 23px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .period-time-container {
        padding: 12px 20px 35px;
        position: relative;
    }

    .period-time-checkbox {
        margin-right: 5px;
    }

    .el-calendar {
        --el-calendar-cell-width: 50px;
    }

    .contract-calendar-table-row .cell {
        overflow: visible;
    }

    .contract-calendar-table-row >>> .meta-list-input {
        flex: 1 1 80%;
        min-width: 0;
    }

    .set-calendar-table {
        width: 100%;
        color: #8c8c8c !important;
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;
    }

    .set-calendar-table thead,input {
        color: #8c8c8c !important;
    }

    .table-row-add-btn {
        cursor: pointer;
    }

    .table-bottom-btns {
        color: var(--lightBlue);
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        font-family: SourceHanSansSC;
        font-style: normal;
    }

    .ctc-tag-wrapper {
        display: inline-block;
        margin-right: 10px;
    }

</style>
