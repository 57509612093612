<template>
    <div>
        <el-dialog
                v-model="dialogVisible"
                title=""
                width="840px"
                custom-class="contract-calendar-dialog"
                :close-on-click-modal="false"
                @closed="setContractCalendarDialogClosed(waitSetCalendarContracts[0])">
            <ContractCalendar v-if="forceRefresh" :contract="currentContract" @callback="waitSetCalendarContracts.splice(0, 1)">

            </ContractCalendar>
        </el-dialog>
    </div>

</template>

<script>
    import {getWaitForCalendarContracts, setContractCalendar} from "../api/api";
    import {colorLog, MetaMessage} from "../util";
    import config from "../config";
    import ContractCalendar from "../components/ContractCalendar";

    export default {
        name: "ContractCalendars",
        props: [],
        components: {ContractCalendar},
        mounted() {
            this.initCalendarDialog();
        },
        data() {
            return {
                metaMessage: new MetaMessage(),
                waitSetCalendarContracts: [],
                dialogVisible: false,
                currentContract: null,
                forceRefresh: false,
            }
        },
        watch: {
            'waitSetCalendarContracts.length': {
                handler(newValue, oldValue) {
                    if (newValue < oldValue) {
                        this.forceRefresh = false;
                        this.dialogVisible = false;
                        setTimeout(() => {
                            this.setCurrentContract();
                        }, 300);
                    }
                }
            }
        },
        methods: {
            initCalendarDialog: function () {
                getWaitForCalendarContracts().then(res => {
                    console.log('getWaitForCalendarContracts res:', res);
                    this.waitSetCalendarContracts = [];
                    for (const contract of res.data.data) {
                        const contractCopy = JSON.parse(JSON.stringify(contract));
                        contractCopy.id = contractCopy.id + "";
                        this.waitSetCalendarContracts.push(contractCopy);
                    }
                    this.setCurrentContract();
                }).catch(err => {
                    console.error('getWaitForCalendarContracts  err:', err);
                })
            },
            setCurrentContract: function () {
                if (this.waitSetCalendarContracts.length > 0) {
                    this.forceRefresh = true;
                    this.dialogVisible = true;
                    this.currentContract = this.waitSetCalendarContracts[0];
                } else {
                    this.forceRefresh = false;
                    this.dialogVisible = false;
                    this.currentContract = {};
                }
            },
            setContractCalendarDialogClosed: function (contract) {
                if (!contract) return;
                setContractCalendar(contract.id, []).then(res => {
                    console.log('setContractCalendarDialogClosed   res ==== ', res);
                }).catch(err => {
                    console.error('setContractCalendar err contract.id === ' + contract.id + ' === ', err);
                });
            }
        }

    }
</script>

<style scoped>

    .tl {
        text-align: left;
        padding: 0 60px;
    }

    .t1 {
        font-size: 16px;
        padding: 5px 0;
    }

    .calendar-btns {
        margin-top: 20px;
        height: 50px;
        text-align: right;
    }

    .c-red {
        color: red;
    }

    .generate-calendar-btn {
        margin-right: 30px;
    }

    .is-selected {
        color: #1989fa;
    }

    .calendar-edit-img-container {
        display: inline-block;
        width: 25px;
        text-align: center;
    }

    .calendar-edit-img {
        display: inline-block;
        width: 20px;
        cursor: pointer;
        vertical-align: top;
    }

    .time-container {
        display: flex;
        align-items: center;
    }

    .time-text {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        line-height: 23px;
        padding-left: 10px;
        padding-right: 10px;
    }

</style>
