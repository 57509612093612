<template>
  <el-table-column
      :prop="prop"
      :label="label"
      :width="width"
      :min-width="minWidth"
      :align="align"
      :sortable="sortable"
      :show-overflow-tooltip="false"
      class="meta-list-column"
  >
    <template #default="scope">
      <div class="meta-list-column-floor"
           @click.stop="handleColumnCellClicked(scope.row,$event)">
        <slot name="default" :row="scope.row" :col="scope.col" :align="align">
          <span class="meta-list-column-normal">{{scope.row[prop]}}</span>
        </slot>
      </div>
    </template>
  </el-table-column>
</template>

<script>
import {META_LIST_COLUMN_TYPES} from "./MetaListEnumberable";
import {colorLog} from "../../util";
export default {
  name: "MetaListColumn",
  components: {},
  emits:["onColumnCellClicked","onChange"],
  props:{
    prop:{
      require:false,
    },
    label:{
      require:false,
    },
    minWidth:{
      require:false,
    },
    width:{
      require:false,
    },
    sortable:{
      require:false,
    },
    "showOverflowTooltip":{
      require:false,
      default:true
},
  align:{
    require:false,
  },
  columnType:{
    require:false,
    default:META_LIST_COLUMN_TYPES.INPUT
  }
  },
  data(){
    return {
      META_LIST_COLUMN_TYPES,
    }
  },
  watch:{
  },
  computed:{

  },
  methods:{
    handleColumnCellClicked(scope,e){
      this.$emit('onColumnCellClicked',scope,e,this);
    },

  }
}
</script>

<style scoped>
.meta-list-column .cell{
  /*overflow: visible !important;*/
}
.meta-list-column{
  z-index:2;
}
.meta-list-column-floor{
  position: relative;
  display: flex;
  align-items: center;
}
.meta-list-column-floor >>> .meta-list-input{
  flex:1
}
.meta-list-column-floor >>> .el-date-editor .el-input__inner{
  border-color:transparent;
  background: transparent;
  cursor: pointer;
}
.meta-list-column-floor >>> .el-date-editor .el-input__inner:hover{
  box-shadow: 0 0 3px var(--gray-2);

}
.meta-list-column-floor >>> .el-date-editor .el-input__inner.el-input__inner--focus{
  cursor: revert;
  box-shadow: 0 0 3px var(--gray-3);
  background: white;
}
</style>
